import CourseMain from './main/courseMain.js'
import BriefingsMain from './briefings/main.js'
// import "./lp/ai_business_planning";

if(document.getElementById('course_main')) {
  CourseMain.create();
}

if(document.getElementById('briefings_main')) {
  BriefingsMain.create();
}

if(document.getElementById('exam_page')){
  import('./exam/exam').then((module) => {
    return module.default.create();
  });
}