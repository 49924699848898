export default {
  data: {
    displayGlobalNaviInner: false,
    offset: 0,
    lastPosition: 0,
    ticking: false,
  },
  computed: {
    globalNaviIsShow(){
      return this.scrollY > 150 && this.ticking
    },
    sideNaviIsShow(){
      return this.scrollY > 150
    },
    globalNaviInnerIsShow(){
      return this.displayGlobalNaviInner ? { display: 'block' } : false;
    },
    globalNaviIsOpen(){
      return this.displayGlobalNaviInner ? 'close':'open';
    },
    spNaviInnerIsShow(){
      return this.scrollY > 150
    },
    isLeadAreaShow() {
      return this.scrollY > 500 &&  document.body.offsetHeight - this.footer.height - 500 > this.scrollY
    }
  },
  methods: {
    toggleNaviInner(){
      this.displayGlobalNaviInner = !this.displayGlobalNaviInner
    },
    onScroll(){
      this.lastPosition = this.scrollY;
    },
    setTicking(){
      this.ticking = this.lastPosition > this.scrollY ? true : false
      return new Promise(function (resolve){
        resolve()
      })
    },
    globalNaviOnScroll(){
      this.setTicking().then(this.onScroll())
    },
  }
};
