export default {
  data: {
    footer: {
      height: 0,
    },
    sideNavi: {
      height: 0,
      bottom: '',
    },
  },
  computed: {
    navPosition() {
      return this.overScroll && !this.margin && this.windowWidth  > 1150 ? 'l_side_nav-fixed' : false
    },
    overScroll() {
      return this.scrollY > 650
    },
    margin() {
      return (document.body.offsetHeight - this.footer.height - this.sideNavi.height) < this.scrollY
    },
    fixPosition() {
      return this.margin && this.windowWidth > 1150 ? { top: 'auto', bottom: '100px'}: false;
    }
  },
  methods: {
    hasSideNav() {
      return document.getElementsByClassName('l_side')[0] 
    },
    setSideNavibottom() {
      this.sideNavi.height = this.hasSideNav() && this.hasSideNav().offsetHeight
      this.sideNavi.bottom = document.body.offsetHeight;
    },
    setFooterHeight() {
      const footer = document.getElementById('l_footer')
      this.footer.height = footer.offsetHeight;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setFooterHeight()
    })
  }
};
