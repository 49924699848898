import Vue from 'vue/dist/vue.esm'

import GlobalNaviMixin from '../main/globalNavi.js'

export default {
  create(){
    new Vue({
      el: "#briefings_main",
      mixins: [GlobalNaviMixin],
      data: {
        scrollY: 0,
        windowWidth: 0,
        currentWeek: 0,
      },
      methods: {
        handleScroll() {
          this.scrollY = window.scrollY
        },
        setWindowWidth(){
          this.windowWidth = window.innerWidth
        },
        mountMethods() {
          this.handleScroll()
          this.globalNaviOnScroll()
        },
        resizeMethod(){
          this.setWindowWidth()
        },
        showSchedule(week) {
          return this.currentWeek === week;
        },
        goAheadWeek() {
          this.currentWeek++;
        },
        backWeek() {
          this.currentWeek--;
        }
      },
      mounted() {
        this.setWindowWidth()
        window.onresize = this.resizeMethod
        window.onscroll = this.mountMethods
      }
    });
  }
};
