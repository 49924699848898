export default {
  data: {
    // 説明会申し込み用
    name: null,
    kana_name: null,
    schedule: null,
    phone_number: null,
    email: null,
    // リクルーティングパートナー申し込み用
    company_name: null,
    employee_name: null,
    company_phone_number: null,
    validation: {
      // 説明会申し込み用
      name: {
        locale: '名前',
        status: false,
        target: false,
        error: null
      },
      kana_name: {
        locale: 'ふりがな',
        status: false,
        target: false,
        error: null
      },
      schedule: {
        locale: '日程',
        status: false,
        target: false,
        error: null
      },
      phone_number: {
        locale: '電話番号',
        status: false,
        target: false,
        error: null
      },
      email: {
        locale: 'メールアドレス',
        status: false,
        target: false,
        error: null
      },
      // リクルーティングパートナー申し込み用
      company_name: {
        locale: '会社名',
        status: false,
        target: false,
        error: null
      },
      employee_name: {
        locale: 'お名前',
        status: false,
        target: false,
        error: null
      },
      company_phone_number: {
        locale: '電話番号',
        status: false,
        target: false,
        error: null
      },
    }
  },
  mounted() {
    for (let el of document.getElementsByClassName("entry_input")) {
      if (el.firstChild.defaultValue && el.firstChild.name && el.firstChild.name.match(/\[([^\[\]\s　]+)/)[1] !== "undefind" ) {
        this[el.firstChild.name.match(/\[([^\[\]\s　]+)/)[1]] = el.firstChild.defaultValue
      }
    }
  },
  methods: {
    setTarget(e) {
      const clickPropatyName = this.formatString(e.target.id);
      if (this[clickPropatyName] === null) {
        this[clickPropatyName] = '';
        this.validation[clickPropatyName].target = true;
      }
    },
    outFocus(e) {
      const clickPropatyName = this.formatString(e.target.id);
      this.isValid(this[clickPropatyName], clickPropatyName);
    },
    isValid(propaty, selectedData) {
      this.checked(propaty, selectedData);
    },
    checked(propaty, selectedData) {
      const checkWord = propaty;
      if (this.isNotEmptyPhoneNumberOrEmail(checkWord, selectedData)) {
        this.validation[selectedData].error = this.addFormatErrorMessage(
          checkWord,
          selectedData
        );
        this.validation[selectedData].status = true;
        return;
      }
      if (checkWord === '' || checkWord === null){
        this.validation[selectedData].error = this.addErrorMessage(selectedData);
        this.validation[selectedData].status = true;
        return;
      } else {
        this.validation[selectedData].error = undefined;
        this.validation[selectedData].status = false;
      }
    },
    formatString(eventTargetId) {
      let sliceWord
      if (eventTargetId.includes('briefing_entry_')) {
        sliceWord = 'briefing_entry_';
      }
      else if (eventTargetId.includes('recruiting_partner_entry_')) {
        sliceWord = 'recruiting_partner_entry_';
      }
      return eventTargetId.replace(sliceWord, '');
    },
    addErrorMessage(selectedData) {
      switch (selectedData) {
        case 'schedule':
          return `${ this.validation[selectedData].locale }を上記のカレンダーから選択してください。`;
          break;
        case 'company_phone_number':
          // リクルーティングパートナー申し込みは電話番号が必須でないため
          return undefined;
          break;
        default:
          return `${ this.validation[selectedData].locale }を入力してください。`;
          break;
      }
    },
    showCheckCircle(attr) {
      switch(attr) {
        case 'email':
        case 'phone_number':
        case 'company_phone_number':
          return this.validation[attr].error === null && this.validation[attr].status === true
          break
        case 'name':
        case 'company_name':
        case 'employee_name':
        case 'kana_name':
          return this.validation[attr].error === undefined
          break
        default:
          return false
          break
      }
    },
    addFormatErrorMessage(checkWord, selectedData) {
      switch (selectedData) {
        case 'phone_number':
        case 'company_phone_number':
          const phonePattern = /^(?!0120)([a-zA-Z0-9]{9,12})$/;
          return phonePattern.test(checkWord)
            ? null
            : '電話番号は-(ハイフン)無しの半角英数字で9~13文字以内で入力して下さい。フリーダイヤルはご利用できません。';
          break;
        case 'email':
          const emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return emailPattern.test(checkWord)
            ? null
            : 'メールアドレスに『@』を挿入してください。';
          break;
        default:
          break;
      }
    },
    isNotEmptyPhoneNumberOrEmail(checkWord, selectedData) {
      return !!checkWord && ['phone_number', 'email', 'company_phone_number'].includes(selectedData)
    },
  }
};
