import Vue from 'vue/dist/vue.esm'

import SideNaviMixin from './sideNavi.js'
import GlobalNaviMixin from './globalNavi.js'
import FormValidation from './formValidation.js'

export default {
  create(){
    new Vue({
      el: "#course_main",
      mixins: [SideNaviMixin, GlobalNaviMixin, FormValidation],
      data: {
        scrollY: 0,
        windowWidth: 0,
      },
      methods: {
        handleScroll() {
          this.scrollY = window.scrollY
        },
        setWindowWidth(){
          this.windowWidth = window.innerWidth
        },
        mountMethods() {
          this.handleScroll()
          this.setSideNavibottom()
          this.globalNaviOnScroll()
        },
      },
      mounted() {
        this.setWindowWidth()
        window.onresize = this.setWindowWidth
        window.onscroll = this.mountMethods
      }
    });
  }
};